.splash{

    padding-top: 10%;
    padding-left: 5%;
    padding-right: 5%;
    line-height: 2em;
    
    h1 {
        font-size: 52px;
        margin-top: 0;
        z-index: 99999;
        opacity: 100;
    }

    .splash-text{
        opacity: 90%;
        font-weight: 500;

        #word-hidden{
            opacity: 0.5;
            transition: 0.9s;

            &:hover{
                opacity: 1;
                transition: 0.9s;
            }
        }

        p{
            font-size: 22px;
        }

        ul{
            list-style: none;
        }

        li{
            width: fit-content;

            &:before{
                content: '~ ';
            }

        }
    }
}