.email-form{
    margin-left: 5%;
    
    span{
        margin-left: 15px;
        color: #1cb495;
        font-weight: bold;
    }

    #email{
        background-color: transparent;
        border: 1px solid gray;
        border-radius: 7px;
        outline: 0;
        padding: 0 1em;
        text-decoration: none;
        width: 16%;
        line-height: 2em;
        color: gray;
        font-size: 16px;
        font-family: 'Inconsolata';
        transition: 0.5s;

        &:focus{
            color: white;
            border: 1px solid white;
            transition: 0.5s;
        }

        &:hover{
            color: white;
            border: 1px solid white;
            transition: 0.5s;
        }
    }

    #submit-button{
        background-color: #1cb495;
        border: 1px solid white;
        color: white;
        border-radius: 7px;
        border: 0;
        cursor: pointer;
        display: inline-block;
        font-weight: bold;
        line-height: 2em;
        padding: 0 0.75em 0 0.75em;
        margin-left: 15px;
        text-align: center;
        text-decoration: none;
        white-space: nowrap;
        font-family: 'Inconsolata';
        font-size: 16px;
        transition: 0.5s;
    
        &:hover {
            background-color: mix(#1cb495, #ffffff, 70%);
            transition: 0.5s;
        }
    }
    
}