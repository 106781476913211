@import url('https://fonts.googleapis.com/css2?family=Inconsolata:wght@300;500;900&display=swap');

.App{
  color: white;
  font-family: 'Inconsolata';  
  width: 100vw;
  height: 100vh;
  word-spacing: 0.1em;
  border: 0;
  margin: 0;

  .background-tint{
    min-height: 100%;
    min-width: 1024px;
  
    /* Set up proportionate scaling */
    width: 100%;
    height: auto;
  
    /* Set up positioning */
    position: fixed;
    top: 0;
    left: 0;
  
    filter: brightness(25%);

    z-index: -1;
  }

}