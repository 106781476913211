footer{
    padding-left: 2%;

    ul{
        list-style: none;
    }

    a{
        text-decoration: none;
        color: inherit;
        opacity: 80%;
    }

    .envelope{
        opacity: 80%;
    }

    .copyright{
        opacity: 50%;
    }
}